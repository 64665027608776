import { useEffect, useContext, useState } from "react";
import React from 'react'

import Context from "../../Context";
import logo from "../../Assets/logo.png";
import styles from "./index.module.scss";
import dotenv from 'dotenv';

require('dotenv').config();

const URL_API = process.env.REACT_APP_API_HOST

const Debit = () => {

  const {token } = useContext(Context);

  const queryParams = new URLSearchParams(window.location.search)
  const callback_uri = queryParams.get("callback_uri");

  const iFrameTabapay = process.env.REACT_APP_TABAPAY_IFRAME_URL;

  // console.log('iFrameTabapay',iFrameTabapay);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createAccount = async (params: any) => {
    let status = 'FAILURE'

    const data = {
      token: params.token,
      firstname: params.firstname,
      lastname: params.lastname,
      expDate: params.expDate,
    }

    const response = await fetch(URL_API + "/api/addCard", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      },
      body: JSON.stringify(data),
    });

    // console.log('response',response);

    if (response.status === 200) {
      status = 'SUCCESSFUL';
    }

    if (response.status === 400) {
      const data = await response.json();
      alert(data.msg + '(' + data.error + ') Please try with other card');
    }

    callbackUrl(status);

    return;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackUrl = (status: string) => {

    // console.log("callback_orig", callback_uri)

    if (callback_uri) {
      //FIX para el callback_uri  :)
      let buff = new Buffer(decodeURIComponent(callback_uri), 'base64');
      let uri = buff.toString('ascii') + '?status=' + status;
      // console.log("callback_fix_uri", uri);
      window.location.replace(uri);
      return;
    }

    window.parent.postMessage({
      appname: 'XPAY-LADONWARE-API',
      status: status
    }, "*");
  }

  const [message, setMessage] = useState<string>(
    "No data received from IFrame, yet."
  );

  useEffect(() => {
    const handler = (event: any) => {
      // //console.log("ev", event);
      if (event.data !== "Close") {
        if (typeof (event.data) === "string") {
          var asData = event.data.split("|");
          if (asData.length === 5) {
            // //console.log("okey");
            //console.log(asData[0]);  // asData[ 0 ] contains the Last 4
            //console.log(asData[1]);  // asData[ 1 ] contains the Expiration Date in YYYYMM Format
            //console.log(asData[2]);  // asData[ 2 ] contains the firstName
            //console.log(asData[3]);  // asData[ 2 ] contains the lastName
            //console.log(asData[4]);  // asData[ 2 ] contains the Card Token
            const params = {
              firstname: asData[2],
              lastname: asData[3],
              expDate: asData[1],
              token: asData[4],
            }
            createAccount(params)

          }
          else {
            // Data Error
            //console.log("data error");
          }
        }
      }
      else {
        // Close or Cancel
        //console.log("close or cancel");
        callbackUrl("CANCELLED");
      }
      // setMessage(ev.data.message);
    };

    window.addEventListener("message", handler, false);

    // Clean up
    return () => window.removeEventListener("message", handler); // The "message" param should match with the iframe.js file
  }, [callbackUrl, createAccount]);


  return (
    <>
      <img className={styles.logoImg} src={logo} alt="Workjam logo" />
      <iframe title="Debit" id="sso" src={iFrameTabapay} width="100%" height={600} ></iframe>
    </>
  );

};

Debit.displayName = "Debit";

export default Debit;

