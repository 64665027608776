import React, {useEffect, useContext} from "react";
import {usePlaidLink} from "react-plaid-link";

import Context from "../../Context";
import {DataItem} from "../../dataUtilities";
import {AuthGetResponse} from "plaid";

require('dotenv').config();

const URL_API = process.env.REACT_APP_API_HOST

const Link = () => {
    const {linkToken, dispatch} = useContext(Context);

    //const [error, setError] = useState<ErrorDataItem | null>(null);
    //const [isLoading, setIsLoading] = useState(false);

    const queryParams = new URLSearchParams(window.location.search)
    const callback_uri = queryParams.get("callback_uri");
    const token = queryParams.get("token") || '';

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const createAccount = async (account: any) => {
        let status = 'FAILURE';

        let acct = await transformAuthData(account)

        // let acct = await getAccountData() || [];

        if (acct.length === 0) {
            callbackUrl(status);
            return;
        }

        //console.log('Get Account: ', acct[0]);

        const data = {
            accountNumber: acct[0].account,
            routingNumber: acct[0].routing,
            accountType: acct[0].acctType
        }

        const response = await fetch(URL_API + "/api/addAccount", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {
            status = 'SUCCESSFUL';
        }

        callbackUrl(status);

        return;
    }


    const callbackUrl = (status: string) => {

        if (callback_uri) {
            let buff = new Buffer(callback_uri, 'base64');
            let uri = buff.toString('ascii') + '?status=' + status;
            window.location.replace(uri);
            return;
        }

        window.parent.postMessage({
            appname: 'XPAY-LADONWARE-API',
            status: status
        }, "*");
    }

    const transformAuthData = (data: AuthGetResponse) => {
        return data.numbers.ach!.map((achNumbers) => {
            const account = data.accounts!.filter((a) => {
                return a.account_id === achNumbers.account_id;
            })[0];
            const balance: number | null | undefined =
                account.balances.available || account.balances.current;
            const acctType = account.subtype === 'checking' ? 'DDA' : 'SAV';
            const obj: DataItem = {
                name: account.name,
                acctType: acctType,
                balance: formatCurrency(balance, account.balances.iso_currency_code),
                account: achNumbers.account!,
                routing: achNumbers.routing!,
            };
            return obj;
        });
    };

    const formatCurrency = (
        number: number | null | undefined,
        code: string | null | undefined
    ) => {
        if (number != null && number !== undefined) {
            return ` ${parseFloat(number.toFixed(2)).toLocaleString("en")} ${code}`;
        }
        return "no data";
    };

    const onSuccess = React.useCallback(
        async (public_token: string) => {
            // send public_token to server
            const setToken = async () => {
                const response = await fetch(URL_API + "/api/set_access_token", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                    },
                    body: `public_token=${public_token}`,
                });
                if (!response.ok) {
                    dispatch({
                        type: "SET_STATE",
                        state: {
                            itemId: `no item_id retrieved`,
                            accessToken: `no access_token retrieved`,
                            isItemAccess: false,
                        },
                    });
                    return;
                }
                return await response.json();

            };

            let account = await setToken();

            //console.log(account);

            dispatch({type: "SET_STATE", state: {linkSuccess: true}});

            await createAccount(account);

            window.history.pushState("", "", "/");
        },
        [createAccount, dispatch]
    );


    const onExit = React.useCallback(
        (error, metadata) => {
            // log and save error and metadata
            // handle invalid link token
            //  //console.log('ventana cerrada!!!!');

            callbackUrl('CANCELLED');
            ////console.log(metadata);
            if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
                // generate new link token
                // //console.log('nuevo token!');
            }
            // to handle other error codes, see https://plaid.com/docs/errors/
        },
        [],
    );

    let isOauth = false;
    const config: Parameters<typeof usePlaidLink>[0] = {
        token: linkToken!,
        onExit,
        onSuccess
    };

    if (window.location.href.includes("?oauth_state_id=")) {
        // TODO: figure out how to delete this ts-ignore
        // @ts-ignore
        config.receivedRedirectUri = window.location.href;
        isOauth = true;
    }

    const {open, ready} = usePlaidLink(config);

    useEffect(() => {
        //open();
        if (ready) {
            open();
        }
    }, [ready, open, isOauth]);

    return (
        <br/>
    );
};

Link.displayName = "Link";

export default Link;


